import React from "react";

/* Util. */
import {useHistory} from "react-router-dom";

/* Assets */
import logo from "../../assets/img/logo.svg";

/* Styles */
import css from "./welcomePage.module.sass"

const WelcomePage = () => {

    let history = useHistory();

    return(
        <div className={css.wrap} onClick={()=>history.push("/login")}>
            <img src={logo} alt={"nibchain logo"}/>
        </div>
    );
}

export default WelcomePage;