export const certificateActions = {
    setLocalCertificates: '/nibchain/certificates/SET_LOCAL_CERTIFICATES',
    setNewCertificates: '/nibchain/certificates/SET_NEW_CERTIFICATES',
    sortCertificates: '/nibchain/certificates/SORT_CERTIFICATES',
    updateCertificateField: '/nibchain/certificates/UPDATE_CERTIFICATE_FIELD',
    toggleCertificateStatus: '/nibchain/certificates/TOGGLE_CERTIFICATE_STATUS',
    resetLocalCertificates: '/nibchain/certificates/RESET_LOCAL_CERTIFICATES',
    addVariantStates: '/nibchain/certificates/ADD_VARIANT_STATES',
    setCurrentEditingComment: '/nibchain/certificates/SET_CURRENT_EDITING_COMMENT',
    resetCertificates: '/nibchain/certificates/RESET_CERTIFICATES',
    removeNewCertificate: '/nibchain/certificates/REMOVE_NEW_CERTIFICATE',
    addError: '/nibchain/certificates/ADD_ERROR',
    clearErrors: '/nibchain/certificates/CLEAR_ERRORS',
    filterByDate: '/nibchain/certificates/FILTER_BY_DATE',
    addFilter: '/nibchain/certificates/ADD_FILTER',
    filterBySearch: '/nibchain/certificates/FILTER_BY_SEARCH',
    filterByStatus: '/nibchain/certificates/FILTER_BY_STATUS',
    resetFilters: '/nibchain/certificates/RESET_FILTERS',
};

export const onCertificatesSort = (sortBy, order) => (dispatch) => {
    dispatch({
        type: certificateActions.sortCertificates,
        payload: { sortBy, order },
    });
};

export const setLocalCertificates = (certificates) => (dispatch) => {
    dispatch({
        type: certificateActions.setLocalCertificates,
        payload: certificates,
    });
};

export const updateCertificateField = (id, key, value) => (dispatch) => {
    dispatch({
        type: certificateActions.updateCertificateField,
        payload: { id, key, value },
    });
};

export const toggleCertificateStatus = (id, stateName, states) => (dispatch) => {
    dispatch({
        type: certificateActions.toggleCertificateStatus,
        payload: { id, stateName, states },
    });
};

export const resetLocalCertificates = () => (dispatch) => {
    dispatch({ type: certificateActions.resetLocalCertificates });
};

export const addVariantStates = (states) => {
    return {type: certificateActions.addVariantStates, payload: states}
}

export const addNewCertificate = (newCertificate) => (dispatch) => {
    dispatch({
        type: certificateActions.setNewCertificates,
        payload: newCertificate
    })
}

export const setCurrentEditingComment = (id, value) => (dispatch) => {
    dispatch({
        type: certificateActions.setCurrentEditingComment,
        payload: {
            id,
            value
        }
    })
}

export const resetCertificates = () => {
    return {type: certificateActions.resetCertificates}
}

export const removeNewCertificate = (id) => {
    return {type: certificateActions.removeNewCertificate, payload: { id }}
}

export const addError = (id, field, message) => {
    return {
        type: certificateActions.addError,
        payload: {
            id, field, message
        }
    }
}

export const clearErrors = () => {
    return {type: certificateActions.clearErrors}
}

export const filterCertificatesByDate = (startDate, endDate) => (dispatch) => {
    dispatch({
        type: certificateActions.filterByDate,
        payload: { startDate, endDate },
    });
};

export const filterCertificatesBySearch = (searchTerm) => (dispatch) => {
    dispatch({
        type: certificateActions.filterBySearch,
        payload: { searchTerm },
    });
};

export const filterCertificatesByStatus = (status) => (dispatch) => {
    dispatch({
        type: certificateActions.filterByStatus,
        payload: { status },
    });
};

export const addCertificatesFilter = (type, value) => (dispatch) => {
    dispatch({
        type: certificateActions.addFilter,
        payload: { type, value },
    });
};

export const resetFilters = () => (dispatch) => {
    dispatch({
        type: certificateActions.resetFilters,
    });
};

