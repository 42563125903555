import React, { useEffect } from "react";
/* Redux */
import {useSelector, connect, useDispatch} from "react-redux";
import { initSession, logout, refreshSession} from "./redux/session-reducer";
import { toggleHelp, getPublicPlatformSettings } from "./redux/app-reducer";

/* Libs */
import { Route, useLocation, useHistory } from "react-router-dom";
import { asyncComponent } from "react-async-component";

/* Material UI */
import withRoot from "./MUITheme";

/* Components */
import LoginPage from "./pages/LoginPage/LoginPage";
import WelcomePage from "./pages/WelcomePage/WelcomePage";
import NibLoader from "./components/common/NibLoader/NibLoader";
import PasswordResetPage from "./pages/PasswordResetPage/PasswordResetPage";
import RegistrationPage from "./pages/RegistrationPage/RegistrationPage";
import Private, { Child } from "./pages/Private";
import Public from "./pages/Public";

import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";
import { useChat } from './userHooks/useChat'

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'

/* Styles */
import "./sass/main.sass";
import {initRouterAction, routerLinksSelector} from "./redux/router-reducer";
import {getVariantStates} from "./redux/certificates/certificates-reducer";

TimeAgo.addDefaultLocale(en)
const PUBLIC_PAGES = ["/registration", "/login", "/landings", "/404", "/password-reset"];
const NAKED_PAGES = ["/download"];
const UNPROTECTED_PAGES = ["/dashboard", "/products", "/orders", "/on_the_go", "/documents"];

const getAsyncComponent = (name, resolve) =>
	asyncComponent({
		name,
		resolve,
		LoadingComponent: NibLoader,
	});

/* Async Components */
const AdminMainMenuPageContainer = asyncComponent({
	name: "AdminMainMenuPageContainer",
	resolve: () =>
		import("./pages/AdminMainMenuPage/AdminMainMenuPageContainer").then(
			(x) => x.default
		),
	LoadingComponent: NibLoader,
});

const CommunicationPageContainer = asyncComponent({
	name: "Communication",
	resolve: () => import("./pages/Communication").then((x) => x.default),
	LoadingComponent: NibLoader,
});

const CompaniesPageContainer = asyncComponent({
	name: "CompaniesPageContainer",
	resolve: () =>
		import("./pages/CompaniesPage/CompaniesPageContainer").then(
			(x) => x.default
		),
	LoadingComponent: NibLoader,
});

const CompanyTypesPageContainer = asyncComponent({
	name: "CompanyTypesPageContainer",
	resolve: () => import("./pages/CompanyTypesPage").then((x) => x.default),
	LoadingComponent: NibLoader,
});

const GiftCardsPageContainer = asyncComponent({
	name: "GiftCardsPageContainer",
	resolve: () => import("./pages/GiftCardsPage/GiftCardsPage").then((x) => x.default),
	LoadingComponent: NibLoader,
});

const ProductsPageContainer = asyncComponent({
	name: "ProductsPageContainer",
	resolve: () =>
		import("./pages/ProductsPage/ProductsPageContainer").then((x) => x.default),
	LoadingComponent: NibLoader,
});

const OnTheGoPageContainer = asyncComponent({
	name: "OnTheGoPageContainer",
	resolve: () =>
		import("./pages/OnTheGoPage/OnTheGoPageContainer").then((x) => x.default),
	LoadingComponent: NibLoader,
});

const OrdersPageContainer = asyncComponent({
	name: "OrdersPageContainer",
	resolve: () =>
		import("./pages/OrdersPage/OrdersPageContainer").then((x) => x.default),
	LoadingComponent: NibLoader,
});

const ReviewsPagesContainer = asyncComponent({
	name: 'ReviewsPagesContainer',
	resolve: () => import('./pages/ReviewsPage/ReviewsPageContainer').then((x) => x.default),
	LoadingComponent: NibLoader
});

const PlatformSettingsPageContainer = asyncComponent({
	name: 'PlatformSettingsPageContainer',
	resolve: () => import('./pages/PlatformSettingsPage/PlatformSettingsPageContainer').then((x) => x.default),
	LoadingComponent: NibLoader
});

const SpecialFunctionsPageContainer = asyncComponent({
	name: 'SpecialFunctionsPageContainer',
	resolve: () => import('./pages/SpecialFunctionsPage/SpecialFunctionsPageContainer').then((x) => x.default),
	LoadingComponent: NibLoader
});

const PodVaultPageContainer = getAsyncComponent("PodVaultPageContainer", () =>
	import("./pages/PodVaultPage").then((x) => x.default)
);

const UsersRolesWorkgroupsPageContainer = asyncComponent({
	name: "UsersRolesWorkgroupsPageContainer",
	resolve: () =>
		import(
			"./pages/UsersRolesWorkgroupsPage/UsersRolesWorkgroupsPageContainer"
		).then((x) => x.default),
	LoadingComponent: NibLoader,
});

const LandingPagesContainer = asyncComponent({
	name: "LandingPagesContainer",
	resolve: () =>
		import("./pages/LandingPages/LandingPagesContainer").then((x) => x.default),
	LoadingComponent: NibLoader,
});

const ImageBankPageContainer = asyncComponent({
	name: "ImageBankPageContainer",
	resolve: () => import("./pages/ImageBankPage").then((x) => x.default),
	LoadingComponent: NibLoader,
});

const DocumentsPageContainer = asyncComponent({
	name: "DocumentsPageContainer",
	resolve: () =>
		import("./pages/DocumentsPage/DocumentsPageContainer").then(
			(x) => x.default
		),
	LoadingComponent: NibLoader,
});

const DownloadPage = asyncComponent({
	name: "DownloadPage",
	resolve: () =>
		import("./pages/DownloadPage/DownloadPage").then((x) => x.default),
	LoadingComponent: NibLoader,
});

const Page404 = asyncComponent({
	name: "Page404",
	resolve: () => import("./pages/Page404/Page404").then((x) => x.default),
	LoadingComponent: NibLoader,
});

const MarketplaceInventoryOrdersCategoriesContainer = asyncComponent({
	name: 'MarketplaceInventoryOrdersCategoriesContainer',
	resolve: () => import('./pages/MarketplacePage/MarketplaceInventoryOrdersCategoriesContainer').then(x => x.default),
	LoadingComponent: NibLoader
});

const UserProfilePage = asyncComponent({
	name: 'UserProfilePage',
	resolve: () => import('./pages/UserProfilePage/UserProfilePage').then(x => x.default),
	LoadingComponent: NibLoader
});

const AcceptedRoutes = () => {
	useChat()
	return <>
		<Route exact path='/communication'>
			<CommunicationPageContainer />
		</Route>

		<Route path='/companies'>
			<CompaniesPageContainer />
		</Route>

		<Route path='/company_types'>
			<CompanyTypesPageContainer />
		</Route>

		<Route path='/marketplace/mp-gift-cards'>
			<GiftCardsPageContainer />
		</Route>

		<Route path='/orders'>
			<OrdersPageContainer />
		</Route>

		<Route path='/reviews'>
			<ReviewsPagesContainer />
		</Route>

		<Route path='/platform_settings'>
			<PlatformSettingsPageContainer />
		</Route>

		<Route path='/special_functions'>
			<SpecialFunctionsPageContainer />
		</Route>

		<Route path='/orders/pod_vault'>
			<PodVaultPageContainer />
		</Route>

		<Route path='/products'>
			<ProductsPageContainer />
		</Route>

		<Route path='/on_the_go'>
			<OnTheGoPageContainer />
		</Route>

		<Route path='/users_roles'>
			<UsersRolesWorkgroupsPageContainer />
		</Route>

		<Route path='/images'>
			<ImageBankPageContainer />
		</Route>

		<Route path='/documents'>
			<DocumentsPageContainer />
		</Route>

		<Route path='/download'>
			<DownloadPage />
		</Route>
		<Route path="/marketplace">
			<MarketplaceInventoryOrdersCategoriesContainer />
		</Route>
		
		<Route path="/user-profile">
			<UserProfilePage />
		</Route>
	</>
}

let pubnub;

const App = ({
	initSession,
	refreshSession,
	logout,
	toggleHelp,
	getPublicPlatformSettings,
	getVariantStates,
	...props
}) => {

	const session = useSelector((state) => state.session);
	const { user, loading } = session;
	const { platformSettings, loading: appLoading } = useSelector((state) => state.app);
	const { pathname } = useLocation();
	const dispatch = useDispatch()
	let history = useHistory();
	const links = useSelector(routerLinksSelector)
	const disabledLinks = links.filter(link => link.disabled).map(link => link.to)

	useEffect(() => {
		if (user) {
			dispatch(initRouterAction())
			pubnub = new PubNub({
				publishKey: process.env.REACT_APP_PUB_KEY,
				subscribeKey: process.env.REACT_APP_SUB_KEY,
				secretKey: process.env.REACT_APP_SEC_KEY,
				uuid: user.uuid
			});
		}
	}, [user, dispatch])

	useEffect(() => {
		const root = document.documentElement;
		root?.style.setProperty("--accent-color", platformSettings.accentColor.hex);
		root?.style.setProperty("--accent-color-r", platformSettings.accentColor.r);
		root?.style.setProperty("--accent-color-g", platformSettings.accentColor.g);
		root?.style.setProperty("--accent-color-g10", platformSettings.accentColor.g-10);
		root?.style.setProperty("--accent-color-b", platformSettings.accentColor.b);
		document.title = platformSettings.companyName;

		if (platformSettings.faviconUrl) {
			const favicon = document.getElementById("favicon");
			favicon.href = platformSettings.faviconUrl
		}
		
	}, [platformSettings])

	useEffect(() => {
		getPublicPlatformSettings();
		getVariantStates();

		const interval = setInterval( async () => {
			await refreshSession();
		  }, 1000 * 60 * 15);

		(async function () {
			if(!PUBLIC_PAGES.includes(pathname)) {
				let user = await initSession();
				if (!user) {
					if (!PUBLIC_PAGES.includes(pathname)) {
						history.push("/login");
					}
				} else {
					if(pathname == "/") {
						history.push("/dashboard");
					}
					await refreshSession();
				}
			}
		})();
		return () => clearInterval(interval);
	}, []);

	function addClass( classname, element ) {
		var cn = element.className;
		if( cn.indexOf( classname ) != -1 ) {
			return;
		}
		if( cn != '' ) {
			classname = ' '+classname;
		}
		element.className = cn+classname;
	}
	function removeClass( classname, element ) {
		var cn = element.className;
		var rxp = new RegExp( "\\s?\\b"+classname+"\\b", "g" );
		cn = cn.replace( rxp, '' );
		element.className = cn;
	}
	const appContainer = document.getElementById('app-wrap');
    const onScroll = (e) => {
		if(appContainer.scrollTop > 60) {
			addClass("header-size-s", appContainer);
		} else {
			removeClass("header-size-s", appContainer);
		}
    }
	return (
		<div id='app-wrap'  onScroll={onScroll} >
			{loading || appLoading ? <NibLoader line={false} /> : ""}
			<Public>
				<Route path='/landings'>
					<LandingPagesContainer />
				</Route>
				<Route path='/404'>
					<Page404 />
				</Route>
			</Public>

			<Private
				publicRoutes={PUBLIC_PAGES}
				nakedRoutes={NAKED_PAGES}
				unprotectedRoutes={UNPROTECTED_PAGES}
				disabledRoutes={disabledLinks}
				extProps={{
					session,
					toggleHelp,
					logout,
				}}>
				<Child type='Accepted'>
					{
						pubnub ?
							<PubNubProvider client={pubnub} >
								<AcceptedRoutes />
							</PubNubProvider> : null
					}
				</Child>

				<Child type='Denied'>
					<Route exact path='/'>
						<WelcomePage />
					</Route>
					<Route exact path='/login'>
						<LoginPage />
					</Route>
					<Route exact path='/password-reset'>
						<PasswordResetPage />
					</Route>
					<Route exact path='/registration'>
						<RegistrationPage />
					</Route>
				</Child>
			</Private>
		</div >
	);
};

export default connect(null, { initSession, refreshSession, logout, toggleHelp, getPublicPlatformSettings, getVariantStates })(
	withRoot(App)
);
