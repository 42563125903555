import React, {useEffect, useRef, useState} from "react";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import {NavLink, useLocation} from "react-router-dom";
import {dictionary} from "../../general/links";

const NibBreadcrumbs = () => {
    let location = useLocation()
    let unknown = {to: "/", label: "Unknown page go to home"}
    let [breads, setBreads] = useState(unknown)
    const lastParams = useRef('')
    

    useEffect(()=>{
        let pages = location.pathname.slice(1).split("/");

        if(location.pathname === "/" || !pages || pages.length <= 1){
            setBreads(null)
        }
        if(pages && pages.length >= 2){
            let payload = [];
            for (const [i, page] of pages.entries()) {
                if(dictionary[page]){
                    if(dictionary[page]?.withParams) {
                        lastParams.current = location.search;
                    }
                    if(pages[i-1]){
                        payload.push({
                            ...dictionary[page],
                            to: `${dictionary[pages[i-1]].to}${dictionary[page].to}${dictionary[page]?.withParams ? lastParams.current : ''}`
                        })
                    } else {
                        payload.push(dictionary[page])
                    }
                    lastParams.current = ''
                }
            }
            setBreads(payload)
        }
    },[location, lastParams])
    
    if (Array.isArray(breads) && breads?.length > 0) {
        return (
            <Breadcrumbs separator="›" className="breadcrumbs" aria-label="breadcrumb">
                {
                    breads.map((b,i)=>(
                        i === breads.length - 1 ? <Typography key={i} className={"activeCrumb"}>{b.label}</Typography>
                        :
                        <NavLink key={i} to={b.to}> {b.label} </NavLink>
                    ))
                }
            </Breadcrumbs>
        );
    }
    return null;
}

export default NibBreadcrumbs;
