import icon_communication from "../assets/img/menu/icon/communication.png";
import icon_companies from "../assets/img/menu/icon/companies.png";
import icon_company_types from "../assets/img/menu/icon/company_types.png";
import icon_document_vault from "../assets/img/menu/icon/document_vault.png";
import icon_marketplace_admin from "../assets/img/menu/icon/marketplace_admin.png";
import icon_media_vault from "../assets/img/menu/icon/media_vault.png";
import icon_my_products from "../assets/img/menu/icon/my_products.png";
import icon_on_the_go from "../assets/img/menu/icon/on_the_go.png";
import icon_production_orders from "../assets/img/menu/icon/production_orders.png";
import icon_task_lists from "../assets/img/menu/icon/task_lists.png";

const TEMP_DATA = [
    {
        id: 99,
        to: "/user-profile",
        label: "User profile",
        alwaysHide: true,
        roles_allowed: ["admin", "company_admin", "user", "marketplace_admin", "marketplace_manager"],
    },
    {
        id: 24,
        to: "/dashboard",
        label: "Dashboard",
        hide: true
    },
    {
        id: 500,
        to: "/platform_settings",
        label: "Platform Settings",
        roles_allowed: ["admin"],
        icon: icon_company_types,
    },
    {
        id: 600,
        to: "/special_functions",
        label: "Special Functions",
        roles_allowed: ["admin"],
        icon: icon_company_types,
    },
    {
        id: 11,
        to: "/communication",
        label: "Communication",
        roles_allowed: ["admin", "company_admin", "user"],
        icon: icon_communication,
    },
    {
        id: 1,
        to: "/account_approval",
        label: "Account approval",
        hide: true
    },
    {
        id: 2,
        to: "/users_roles",
        label: "Users/roles/workgroups",
        roles_allowed: ["admin", "company_admin"],
        icon: icon_company_types,
    },
    {
        id: 3,
        to: "/companies",
        label: "Companies",
        roles_allowed: ["admin", "company_admin"],
        icon: icon_companies,
    },
    {
        id: 123,
        to: "/company_types",
        label: "Company Types",
        icon: icon_company_types,
    },
    {
        id: 4,
        to: "/products",
        label: "Products",
        // unprotected: true,
        roles_allowed: ["admin", "company_admin", "user"],
        icon: icon_my_products
    },
    {
        id: 5,
        to: "/orders",
        label: "Batches",
        // unprotected: true,
        roles_allowed: ["admin", "company_admin", "user"],
        icon: icon_production_orders
    },
    {
        id: 33,
        to: "/reviews",
        label: "Reviews",
        roles_allowed: ["admin", "company_admin", "user", "marketplace_admin", "marketplace_manager"],
        icon: icon_task_lists,
    },
    {
        id: 7,
        to: "/documents",
        label: "Documents",
        // unprotected: true,
        roles_allowed: ["admin", "company_admin", 'user'],
        icon: icon_document_vault
    },
    {
        id: 9,
        to: "/images",
        label: "Images",
        // unprotected: true,
        roles_allowed: ["admin", "company_admin", 'user'],
        icon: icon_media_vault,
    },
    {
        id: 29,
        to: "/orders/add",
        label: "Add",
        hide: true,
        // roles_allowed: ["admin", "company_admin"]
    },
    {
        id: 30,
        to: "/orders/edit",
        label: "Edit",
        hide: true,
        // roles_allowed: ["admin", "company_admin"]
    },
    {
        id: 6,
        to: "/sensors",
        label: "Sensors/IoT",
        hide: true,
        // roles_allowed: ["admin", "company_admin"]
    },
    {
        id: 8,
        to: "/transactions",
        label: "Transactions",
        hide: true,
        // roles_allowed: ["admin", "company_admin"]
    },
    {
        id: 9,
        to: "/media_vault",
        label: "Media Vault",
        hide: true,
        // roles_allowed: ["admin", "company_admin"]
    },
    {
        id: 10,
        to: "/labels",
        label: "Labels",
        hide: true,
        // roles_allowed: ["admin", "company_admin"]
    },
    {
        id: 12,
        to: "/map",
        label: "Real-time map",
        hide: true,
        // roles_allowed: ["admin", "company_admin"]
    },
    {
        id: 13,
        to: "/customers",
        label: "Customers",
        hide: true,
        // roles_allowed: ["admin", "company_admin"]
    },
    {
        id: 14,
        to: "/inventory",
        label: "Inventory",
        hide: true,
        // roles_allowed: ["admin", "company_admin"]
    },
    {
        id: 15,
        to: "/delivery",
        label: "Delivery",
        hide: true,
        // roles_allowed: ["admin", "company_admin"]
    },
    {
        id: 16,
        to: "/landings",
        label: "Landing sites products",
        hide: true,
        // roles_allowed: ["admin", "company_admin"]
    },
    {
        id: 17,
        to: "/recipes",
        label: "Recipes",
        hide: true,
        // roles_allowed: ["admin", "company_admin"]
    },
    {
        id: 18,
        to: "/payments",
        label: "Payments",
        hide: true,
        // roles_allowed: ["admin", "company_admin"]
    },
    {
        id: 19,
        to: "/contracts",
        label: "Contracts",
        hide: true,
        // roles_allowed: ["admin", "company_admin"]
    },
    {
        id: 20,
        to: "/processes",
        label: "Processes",
        hide: true,
        // roles_allowed: ["admin", "company_admin"]
    },
    {
        id: 31,
        to: "/on_the_go",
        label: "On The Go",
        // unprotected: true,
        roles_allowed: ["admin", "company_admin", 'user'],
        icon: icon_on_the_go,
    },
    {
        id: 32,
        to: "/marketplace",
        label: "Marketplace",
        roles_allowed: ["admin", "marketplace_admin", "company_admin", "marketplace_manager"],
        icon: icon_marketplace_admin,
    },
]

export const dictionary = {
    companies: {to: "/companies", label: "Companies"},
    orders: {to: "/orders", label: "Batches"},
    on_the_go: {to: "/on_the_go", label: "On The Go"},
    landing_edit: {to: "/landing_edit", label: "Landing Edit"},
    products: {to: "/products", label: "Products"},
    add: {to: "/add", label: "Add"},
    edit: {to: "/edit", label: "Edit", withParams: true},
    users_roles: {to: "/users_roles", label: "Users/roles/workgroups"},
    platform_settings: {to: "/platform_settings", label: "Platform Settings"},
    special_functions: {to: "/special_functions", label: "Special Functions"},
    destination_codes: {to: "/destination_codes", label: "Destination QR codes"},
    branding: {to: "/branding", label: "Branding"},
    users: {to: "/users", label: "Users"},
    roles: {to: "/roles", label: "Roles"},
    workgroups: {to: "/workgroups", label: "Workgroups"},
    documents: {to: "/documents", label: "Documents"},
    marketplace: { to: "/marketplace", label: "Marketplace" },
    features: { to: "/features", label: "Features" },
    wrappers: { to: "/wrappers", label: "Wrappers" },
    reviews: { to: "/reviews", label: "Reviews" },
    'rw-products': { to: "/rw-products", label: "Product Reviews" },
    'rw-companies': { to: "/rw-companies", label: "Company Reviews" },
    'pod_vault': { to: "/pod_vault", label: "PODs Vault B2B" },
    'wrapper-types': { to: "/wrapper-types", label: "Wrapper Types" },
    'mp-gift-cards': { to: "/mp-gift-cards", label: "Gift Cards" },
    'mp-inventory': { to: "/mp-inventory", label: "Inventory" },
    'mp-help-editor': { to: "/mp-help-editor", label: "Marketplace Help" },
    'mp-localization': { to: "/mp-localization", label: "Marketplace Localization" },
    'mp-ticket-validation': { to: "/mp-ticket-validation", label: "Ticket Validation" },
    'questions': { to: "/mp-help-editor/questions", label: "Questions" },
    'mp-orders': { to: "/mp-orders", label: "Orders" },
    'mp-orders-old': { to: "/mp-orders-old", label: "OrdersOld" },
    'mp-categories': { to: "/mp-categories", label: "Categories" },
    'mp-icons': { to: "/mp-icons", label: "Icons" },
    'mp-users': { to: "/mp-users", label: "Users" },
    'mp-product-taxes': { to: "/mp-product-taxes", label: "Taxes" },
    'certificates': { to: "/certificates", label: "Certificates & IDs" }
}

export default TEMP_DATA;
