import baseDataReducer from "../baseDataReducer";
import API from "../../api/api";
import {
    addError,
    addVariantStates,
    certificateActions,
    clearErrors,
    filterCertificatesByStatus
} from "./certificates-actions";
import { cloneDeep } from "lodash";
import {onError} from "../error-reducer";
import {format, parse, parseISO} from "date-fns";

const { iniState, reducer, AC, thunks } = baseDataReducer("certificates");

const initialState = {
    ...iniState,
    filters: {},
    sorting: {},
    localCertificates: [],
    editedCertificates: [],
    states: [],
    currentEditingComment: null,
    errors: null,
};

const certificatesReducer = (state = initialState, action) => {
    switch (action.type) {
        case certificateActions.addFilter: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload.type]: action.payload.value
                }
            }
        }
        case certificateActions.addVariantStates: {
            return {
                ...state,
                states: action.payload
            }
        }
        case certificateActions.setNewCertificates: {
            return {
                ...state,
                localCertificates: [...state.localCertificates, action.payload],
                editedCertificates: [...state.editedCertificates, action.payload],
            };
        }
        case certificateActions.setLocalCertificates: {
            return {
                ...state,
                localCertificates: action.payload,
            };
        }
        case certificateActions.sortCertificates: {
            const { sortBy, order } = action.payload;
            const sortedItems = [...state.localCertificates].sort((a, b) => (
                order === 'ASC' ? (a[sortBy] > b[sortBy] ? 1 : -1) : (a[sortBy] < b[sortBy] ? 1 : -1)
            ));
            return {
                ...state,
                localCertificates: sortedItems,
                sorting: { [sortBy]: order },
            };
        }
        case certificateActions.updateCertificateField: {
            const { id, key, value } = action.payload;
            const updatedCertificates = cloneDeep(state.localCertificates);
            const certificate = updatedCertificates.find((cert) => cert.id === id);
            certificate[key] = value;

            const editedCertificates = cloneDeep(state.editedCertificates);
            const originalCertificate = state.items.find((cert) => cert.id === id);
            if (originalCertificate?.[key] == value) {
                return {
                    ...state,
                    localCertificates: updatedCertificates,
                    editedCertificates: editedCertificates.filter((cert) => cert.id !== id),
                };
            } else {
                return {
                    ...state,
                    localCertificates: updatedCertificates,
                    editedCertificates: [...editedCertificates.filter((cert) => cert.id !== id), certificate],
                };
            }
        }
        case certificateActions.toggleCertificateStatus: {
            const { id, stateName, states } = action.payload;
            const updatedCertificates = cloneDeep(state.localCertificates);
            const certificate = updatedCertificates.find((cert) => cert.id === id);

            if(certificate.stateName === 'sold') {
                return { ...state }
            }
            if (stateName !== 'sold') {
                const toggledState = states.find((state) => state.name !== stateName && stateName !== 'sold');
                if (toggledState) {
                    certificate.state = `${toggledState.id}`;
                    certificate.stateName = toggledState.name;
                }
            }

            const originalCertificate = state.items.find((cert) => cert.id === id);
            if (originalCertificate?.state === certificate.state) {
                return {
                    ...state,
                    localCertificates: updatedCertificates,
                    editedCertificates: state.editedCertificates.filter((cert) => cert.id !== id),
                };
            } else {
                return {
                    ...state,
                    localCertificates: updatedCertificates,
                    editedCertificates: [...state.editedCertificates.filter((cert) => cert.id !== id), certificate],
                };
            }
        }
        case certificateActions.resetCertificates:
            return {
                ...state,
                items: [],
                filters: {},
                sorting: {},
                editedCertificates: [],
                newCertificates: [],
                currentEditingComment: null
            }
        case certificateActions.resetLocalCertificates: {
            return {
                ...state,
                localCertificates: cloneDeep(state.items),
                editedCertificates: [],
                newCertificates: [],
                sorting: {},
            };
        }
        case certificateActions.setCurrentEditingComment: {
            return {
                ...state,
                currentEditingComment: action.payload
            }
        }
        case certificateActions.removeNewCertificate: {
            const removingCertificate = state.localCertificates.find(c => c.id === action.payload.id)
            if(removingCertificate?.isNew) {
                return {
                    ...state,
                    localCertificates: state.localCertificates.filter(c => c.id !== action.payload.id),
                    editedCertificates: state.editedCertificates.filter(c => c.id !== action.payload.id),
                };
            }
            else {
                return {...state}
            }
        }
        case certificateActions.addError: {
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.payload.id]: {
                        ...state.errors?.[action.payload.id],
                        [action.payload.field]: action.payload.message
                    }
                }
            }
        }
        case certificateActions.clearErrors: {
            return { ...state, errors: {} }
        }
        case certificateActions.filterByDate: {
            const { startDate, endDate } = action.payload;
            const parsedStartDate = parse(startDate, 'dd.MM.yyyy', new Date());
            const parsedEndDate = parse(endDate, 'dd.MM.yyyy', new Date());

            const filteredCertificates = state.localCertificates.filter((certificate) => {
                const certDate = parse(certificate.created_at, 'dd.MM.yyyy', new Date());
                return certDate >= parsedStartDate && certDate <= parsedEndDate;
            });
            return {
                ...state,
                localCertificates: filteredCertificates,
            };
        }
        case certificateActions.filterBySearch: {
            const { searchTerm } = action.payload;

            const filteredCertificates = state.localCertificates.filter((certificate) => {
                return (
                    certificate?.buyer?.company_name?.toLowerCase().includes(searchTerm) ||
                    certificate?.buyer?.txn_id?.toLowerCase().includes(searchTerm) ||
                    certificate?.buyer?.date?.date?.toLowerCase().includes(searchTerm) ||
                    certificate?.comment?.toLowerCase().includes(searchTerm) ||
                    certificate?.created_at?.toLowerCase().includes(searchTerm) ||
                    certificate?.rdid?.toLowerCase().includes(searchTerm) ||
                    certificate?.price?.toString().includes(searchTerm) ||
                    certificate?.stateName?.toString().includes(searchTerm)
                );
            });

            return {
                ...state,
                localCertificates: filteredCertificates,
            };
        }
        case certificateActions.filterByStatus: {
            const { status } = action.payload;

            const filteredCertificates = state.localCertificates.filter((certificate) => {
                return (
                    certificate?.state?.toString()?.includes(status?.toString())
                );
            });

            return {
                ...state,
                localCertificates: filteredCertificates,
            };
        }
        case certificateActions.resetFilters: {
            return { ...state, filters: {} }
        }
        default:
            return reducer(state, action);
    }
};

export default certificatesReducer;

export const getVariantStateNameById = (id, states) => {
    const foundState = states.find(st => st.id === id);
    return foundState ? foundState.name : '';
}

export const getCertificates = (orderId) => async (dispatch, getState) => {
    dispatch(AC.toggleLoading(true));
    const data = await API.orders.getCertificates(orderId);
    const states = getState().certificates.states;
    if (data?.length) {
        dispatch(AC.setItems(data.map((item) => {
            const date = parseISO(item.created_at);
            const formattedDate = format(date, 'dd.MM.yyyy');
            return {
                ...item,
                created_at: formattedDate,
                stateName: getVariantStateNameById(Number(item?.state) || null, states)
            }
        })));
        dispatch(AC.setTotal(data.length));
    }
    dispatch(AC.toggleLoading(false));
    dispatch(AC.toggleInit(true));
};

export const saveCertificates = (orderId) => async (dispatch, getState) => {
    dispatch(clearErrors())
    const editedCertificates = getState().certificates.editedCertificates;
    let isErrors = false

    for (const certificate of editedCertificates) {
        if ((!certificate?.price || +certificate.price <= 0) && (certificate.stateName === 'exchange')) {
            dispatch(addError(certificate.id, 'price', 'Cannot set certificates with a price of 0 to EXCHANGE.'))
            isErrors = true
        }
    }

    if(isErrors) {
        return null;
    }

    const formattedCertificates = editedCertificates.map(c => {
        if (c?.isNew) {
            return {
                comment: c.comment,
                state: +c.state,
                price: +c.price
            };
        } else {
            return {
                id: +c.id,
                comment: c.comment,
                state: +c.state,
                price: +c.price
            };
        }
    });

    return await API.orders.saveCertificates(orderId, JSON.stringify(formattedCertificates));
};

export const getVariantStates = () => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        const data = await API.marketplace.getVariantStates();
        if (data?.length) {
            dispatch(addVariantStates(data))
        }
        dispatch(AC.toggleLoading(false));
    }
    catch(err){
        console.log(err);
        onError({ status: 0, message: "Error on variant states fetch", dev_info: err });
        dispatch(AC.toggleLoading(false));
        return false;
    }
};
